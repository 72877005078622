.table-logs {
  .table__col {
    &.type {
      width: 122px;
    }

    &.date {
      width: 122px;
    }

    &.obj {
      width: 184px;
    }

    &.actions {
      min-width: 40px;
      width: 40px;
    }
  }
}

.page-content {
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    flex-direction: row;

    > .content {
      max-width: 720px;
      width: calc(100% - 256px - 80px);
    }
  }


}

@import 'variables';
.cdk-overlay-container {
  &.toggle-height {
    position: absolute;
    overflow: auto;

    mat-dialog-content {
      position: relative;
    }

    .cdk-global-overlay-wrapper {
      align-items: start !important;

      @media (min-width: 768px) {
        padding-top: 40px;
      }

      @media (max-width: 767px) {
        padding-top: 0;

        .cdk-overlay-pane {
          height: 100%;
        }

        .close {
          top: 16px;
          right: 16px;

          mat-icon {
            display: flex;
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .btns {
      @media (max-width: 767px) {
        flex-direction: column;
        width: 100%;

        app-btn {
          width: 100%;

          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

.accordion {
  mat-expansion-panel {
    background: transparent;

    &.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
      background: transparent;
    }

    &:hover mat-icon svg path {
      fill: $white;
    }

    &.mat-expansion-panel {
      box-shadow: none;
    }

    mat-expansion-panel-header {
      padding: 0;
      padding-right: 16px;
      transition: 0.3s;

      &:hover {
        @media (min-width: 768px) {
          transition: 0.3s;
          padding-left: 30px;
          padding-right: 30px;
          background: #000 !important;

          mat-panel-title {
            color: #fff;
          }
        }
      }

      .mat-content {
        align-items: center;
      }
    }

    mat-panel-title {
      font-family: 'e-Ukraine', sans-sarif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .mat-expansion-panel-body {
      padding: 0;
      padding-bottom: 16px;
      font-family: 'e-Ukraine', sans-sarif;
      font-size: 14px;
      font-weight: 400;
    }
  }

  &--list {
    mat-expansion-panel {
      border-top: 1px solid #000;
      border-radius: 0;

      &.last {
        border-bottom: 1px solid #000;
      }

      mat-expansion-panel-header {
        height: 82px;

        @media (min-width: 768px) {
          height: auto;
          padding-top: 62px;
          padding-bottom: 62px;
        }
      }
    }

    .mat-expansion-indicator {
      display: none;
    }

    .mat-expanded {
      .mat-expansion-panel-content {
        margin-top: 16px;
      }
    }
  }
}

.tabs {
  mat-tab-group {
    &.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
      flex-grow: 0;
      padding: 0;
      min-width: auto;
      margin: 0 4px;
    }

    .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
      color: #000;
    }

    .mat-mdc-tab .mdc-tab-indicator__content--underline {
      border-color: #000 !important;
    }

    .mdc-tab {
      height: 38px;
    }

    .mat-mdc-tab-labels {
      gap: 24px;

      @media (min-width: 768px) {
        gap: 48px;
      }
    }

    .mdc-tab__text-label {
      font-family: 'e-Ukraine', sans-sarif;
      letter-spacing: normal;
    }

    mat-tab-body {
      margin-top: 24px;

      @media (min-width: 768px) {
        margin-top: 32px;
      }
    }
  }
}

.radio-group {
  mat-radio-group {
    .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
    .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
      border-color: #000;
    }

    .mat-mdc-radio-button.mat-mdc-radio-checked .mat-ripple-element,
    .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
      background-color: #000;
    }

    .mdc-label {
      cursor: pointer;
    }
  }
}

//--------------------------------@font-face
@font-face {
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/e-Ukraine/e-Ukraine-Regular.otf') format('woff2');
}

@font-face {
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/e-Ukraine/e-Ukraine-Medium.otf') format('woff2');
}


@font-face {
  font-family: 'e-Ukraine';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/fonts/e-Ukraine/e-Ukraine-Light.otf') format('woff2');
}

html.contrast {
  filter: grayscale(1);

  //* &:not(.btn-black, .accordion__item-title, .menu-list) {
  //  strong,
  //  span,
  //  b,
  //  i,
  //  img,
  //  figure {
  //    color: #000 !important;
  //    filter: grayscale(1);
  //  }
  //}
  //
  //[style*='color:']:not(.cdk-drag) {
  //  color: #000 !important;
  //  font-weight: bold;
  //}
}

.accessibility {
  .home-menu__slider .owl-carousel .owl-stage-outer .owl-stage {
    height: auto;
  }
}

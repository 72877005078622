.layout {
  .welcome {
    display: flex;
    gap: 12;
    align-items: center;
    margin-top: 38px;


    @media (min-width: 768px) {
      margin-top: 68px;
    }
  }

  .tabs {
    margin-top: 38px;
    gap: 22px;
    padding-top: 4px;

    @media (min-width: 768px) {
      margin-top: 68px;
      gap: 40px
    }

    &__item {
      &:last-child {
        padding-right: 16px;
      }
    }
  }
}

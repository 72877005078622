
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'assets/scss/fonts';
@import 'assets/scss/base';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: 400;
  background-color: white;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
}

html {
  overflow-x: hidden;
}

a,
button {
  text-transform: capitalize;
}

div,
p,
a,
section,
li,
ol,
ul {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  text-transform: none;
  color: inherit;
}

.wrapper {
  max-width: 1216px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &--header {
    max-width: 1440px;
  }
}

.custom-dialog {
  .mdc-dialog__container {
    background-color: #fff;
  }

  .mat-mdc-dialog-container .mdc-dialog__surface .mdc-dialog__content {
    max-height: 100%;
  }

  @media (max-width: 767px) {
    height: 100%;
  }
}

.ci {
  &__footer {
    padding-bottom: 30px;
    border-bottom: 1px solid #000;
  }

  &__content {
    app-contact-item {
      h4 {
        font-size: 20px;
        line-height: 24px;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
